//import './cursor.js';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
//import { initializeScrollAnimations } from './gsap-animation.js';
//import Lenis from 'lenis'
//import './cookies.js';

/*const lenis = new Lenis({
	duration: 1.45,
	direction: 'vertical',
	gestureDirection: 'vertical',
	smooth: true,
	mouseMultiplier: 1,
	smoothTouch: false,
	touchMultiplier: 2,
	infinite: false,
});

function raf(time) {
	lenis.raf(time);
	requestAnimationFrame(raf);
}

requestAnimationFrame(raf);
*/

import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

const swiper = new Swiper('.swiper', {
	modules: [Navigation],
	loop: true,
	slidesPerView: "auto",
	spaceBetween: 10,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

document.addEventListener('DOMContentLoaded', function () {

	document.body.classList.add('start');

	const main = document.querySelector(".section-main");
	let mainTimeline;

	if (main) {
		mainTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: main,
				start: "top top",
				end: "bottom top",
				scrub: true,
				pin: true,
				pinSpacing: true,
				markers: true,
			}
		});

		mainTimeline
			.to(".section-main__full-height img", {
				width: "100%",
				height: "100%",
				duration: 1,
				ease: "none"
			})
			.to(".section-main .section-main__text", {
				opacity: 0,
				duration: 1,
				ease: "none"
			})
			.to(".flatta", {
				top: '100%',
				transform: 'translateY(-100%)',
				duration: 1,
				ease: "none"
			}, 0);
	}

	const architect = document.querySelector(".section-architect__full-height");
	let architectTimeline;

	if (architect) {
		architectTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: architect,
				start: "top top",
				end: "bottom top",
				scrub: true,
				pin: true,
				pinSpacing: true,
				markers: true,
			}
		});

		architectTimeline.to(".section-architect__full-height img", {
			width: "100%",
			height: "100%",
			duration: 1, // Kontrola czasu animacji (opcja, bo scrub działa z przewijaniem)
			ease: "none"
		});

	}

});